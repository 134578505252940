import { createContext, useState } from 'react'
import React from 'react'

const MoovaLotContent = createContext()
const MoovaLotWrapper = ({ children }) => {
  const [moovalotData, setMoovaLotData] = useState(null)
  const [bookingDetails, setBookingDetails] = useState(null)
  const [statistics, setStatistics] = useState(null)




  return (
    <MoovaLotContent.Provider value={{ moovalotData, setMoovaLotData ,bookingDetails, setBookingDetails,statistics, setStatistics}}>
      {children}
    </MoovaLotContent.Provider>
  )
}

export default MoovaLotContent
export { MoovaLotWrapper }
