class StorageHelper {
  setToken(data) {
    localStorage.setItem("moovalot_token", data);
  }

  getToken() {
    return localStorage.getItem("moovalot_token");
  }
  getUserData() {
    return JSON.parse(localStorage.getItem("admin_details"));
  }

  removeToken() {
    localStorage.removeItem("admin_details");
    localStorage.removeItem("moovalot_token");
    
    return localStorage.clear()
  }
}

export default StorageHelper = new StorageHelper();
