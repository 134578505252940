import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import {MoovaLotWrapper} from './CustomHooks/Context/MoovaLot'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools' 
const queryClient=new QueryClient()

import './style.css'
createRoot(document.getElementById('root')).render(
  <QueryClientProvider client={queryClient}>
  <Provider store={store}>
    <MoovaLotWrapper>
      <App />
    </MoovaLotWrapper>
  </Provider>,
  <ReactQueryDevtools initialOpen={true} />
  </QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
